

































import { Component, Vue } from "vue-property-decorator";
import DxDateBox from "devextreme-vue/date-box";
import { ClassReportWizardForm } from "@/shared/dtos/ClassReportWizardForm";
import { API } from "@/shared/api";
import actuacionModule from "@/store/modules/actuacion-module";
@Component({
  components: { DxDateBox },
})
export default class InformeActuaciones extends Vue {
  public form: ClassReportWizardForm = new ClassReportWizardForm();
  public html: string = "";

  created() {
    this.form.ids_actuacion = [];
    actuacionModule.getactuaciones();
  }

  get tipos_actuaciones() {
    if (this.form.ids_actuacion.length === 0) {
      //  this.form.ids_actuacion = actuacionModule.actuaciones.map((x) => x.id);
    }
    return actuacionModule.actuaciones;
  }

  aceptar() {
    actuacionModule.GenerarInformeActuaciones(this.form).then((x) => {
      window.open(API.webApiBaseRootPath + "" + x);
    });
  }
}
